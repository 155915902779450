import mock from 'src/utils/mock';
import type { Member } from 'src/types/members';

const members: Member[] = [
  {
    clubid: '1',
    clubname: 'Test',
    id: '1',
    profileUrl: '',
    name: 'Mmber1',
    parentLastName: 'Last Name',
    parentFirstName: 'Name1',
    groupName: 'All members',
    groups: [
      {
        groupid: 1,
        name: 'All members',
        description: 'All',
        totalNum: 3,
      },
    ],
    email: 'email@email.com',
    phone: '+3000000000',
    active: true,
    prospect: false,
    country: '',
    state: '',
    address1: '',
    address2: '',
  },
  {
    clubid: '2',
    clubname: 'Test 2',
    id: '2',
    profileUrl: '',
    name: 'Mmber2',
    parentLastName: 'Last Name',
    parentFirstName: 'Name2',
    groupName: 'Beginners',
    groups: [
      {
        groupid: 1,
        name: 'Beginners',
        description: 'Beginners',
        totalNum: 3,
      },
    ],
    email: 'email@email2.com',
    phone: '+3000000003',
    active: false,
    prospect: true,
    country: '',
    state: '',
    address1: '',
    address2: '',
  },
];

mock.onGet('/api/club/1/members').reply(200, { members });
