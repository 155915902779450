import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { PostHistoryType } from 'src/types/post';
import axios from 'src/utils/axios_mock';

interface postState {
  history: PostHistoryType[];
  club: string;
  particularPost:any;
}
const initialState: postState = {
  history: [],
  club: '',
  particularPost:{}
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    getPostHistoryData(
      state: postState,
      action: PayloadAction<{ postHistoryData: PostHistoryType[] }>
    ) {
      const { postHistoryData } = action.payload;
      state.history = [...postHistoryData, ...state.history];
    },
    setPostAction(
      state: postState,
      action: PayloadAction<{ postDetail: any }>
    ) {
      const { postDetail } = action.payload;
      state.history = [{ ...postDetail }, ...state?.history];
    },
    emptyPostList(
      state: postState,
      action: PayloadAction<{ postHistory: any }>
    ) {
      const { postHistory } = action.payload;
      state.history = postHistory;
    },
    getPostDetail(state: postState,
      action: PayloadAction<{ postData: any }>
      ){
        const {postData} = action.payload;
        state.particularPost={...postData,...state.particularPost}
    },
    emptyParticularPost(state: postState){
      state.particularPost={}
    }
  },
});

export const getPostHistory = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<{
      postHistoryData: PostHistoryType[];
    }>('/api/postHistory');
    dispatch(slice.actions.getPostHistoryData(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const setPost =
  (postDetail: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPostAction({ postDetail }));
  };
export const emptyPostHistory = (): AppThunk => async (dispatch, getState) => {
  const postHistory = getState().post.history;
  dispatch(slice.actions.emptyPostList({ postHistory }));
};

export const getPostDetail =
  (postData: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.getPostDetail({ postData }));
  };

  export const emptyParticularPost = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.emptyParticularPost());
  };
export const reducer = slice.reducer;

export default slice;
