// @mui
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Stack,
  FilledInput,
  InputAdornment,
  Button,
} from '@mui/material';
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(20, 0),
  backgroundColor: '#e9edf0',
}));

// ----------------------------------------------------------------------

export default function NewsletterMarketing() {
  return (
    <RootStyle>
      <Container>
        <Stack
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          direction={{ xs: 'column', md: 'row' }}
        >
          <Stack
            spacing={3}
            alignItems="center"
            direction={{ xs: 'column', md: 'row' }}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Image
              src="/static/images/newsletter.svg"
              sx={{ width: 80, height: 80, mx: 'primary.main' }}
            />
            <div>
              <Typography variant="h4" gutterBottom>
                Sign Up For Newsletter
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Receive latest update of new features!
              </Typography>
            </div>
          </Stack>

          <FilledInput
            placeholder="Enter your email"
            endAdornment={
              <InputAdornment position="end">
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    height: 56,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  Sign Up
                </Button>
              </InputAdornment>
            }
            sx={{
              p: 0,
              width: 1,
              maxWidth: 466,
              borderRadius: 2,
              backgroundColor: '#f1f6f7',
              '& .MuiFilledInput-input': {
                py: '18px',
                '&::placeholder': {
                  color: 'grey.800',
                },
              },
            }}
          />
        </Stack>
      </Container>
    </RootStyle>
  );
}
