// icons
import directionStraightRight from '@iconify/icons-carbon/direction-straight-right';

// next
//import NextLink from 'next/link';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Container,
  Typography,
  Card,
  Box,
  //SvgIcon,
} from '@mui/material';
// routes
//import Routes from '../../../routes';
// components
import Iconify from 'src/components/Iconify';
import Image from '../../../components/Image';
// ----------------------------------------------------------------------

const SERVICES = [
  {
    name: 'Member Sign Up and Management',
    icon: '/static/images/signup.svg',
    text: 'Create club space and groups, ask your youth member parents to sign up',
    path: '#',
  },
  {
    name: 'Email Notification To Parents',
    icon: '/static/images/emails.svg',
    text: 'Communication with yourth members and their parents is easy. Your posts can be scheduled',
    path: '#',
  },
  {
    name: 'Calendar View',
    icon: '/static/images/calendar.svg',
    text: 'Checkout calendar view to see all class and events schedules',
    path: '#',
  },
  {
    name: 'Free Invoice Generation',
    icon: '/static/images/invoice.svg',
    text: 'Send invoice to parents',
    path: '#',
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0),

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function LandingServices() {
  return (
    <RootStyle>
      <Container sx={{ marginLeft: '-24px' }}>
        <Stack
          sx={{
            maxWidth: 480,
            mb: { xs: 8, md: 5 },
            mx: { xs: 'auto', md: 'unset' },
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>
            Our Services
          </Typography>

          <Typography variant="h2" sx={{ mt: 2, mb: 3 }}>
            We Provide Cloud Tools
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            It is easy to create your own club on cloud, even just one person
            yourself can manage it.
          </Typography>
        </Stack>

        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 4,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            },
          }}
        >
          {SERVICES.map((service, index) => (
            <ServiceItem key={service.name} service={service} index={index} />
          ))}
        </Box>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type ServiceItemProps = {
  service: {
    name: string;
    text: string;
    path: string;
    icon: any;
  };
  index: number;
};

function ServiceItem({ service, index }: ServiceItemProps) {
  const { name, icon, text, path } = service;

  return (
    <Card
      sx={{
        px: 5,
        py: 8,
        height: 400,
        backgroundColor: '#fffefc',
        textAlign: 'center',
        ...(index === 0 && {
          boxShadow: (theme) => ({ md: theme.shadows[12] }),
        }),
        ...(index === 1 && {
          py: { xs: 5, md: 8 },
          boxShadow: (theme) => ({ md: theme.shadows[20] }),
        }),
        ...(index === 2 && {
          py: { xs: 5, md: 8 },
          boxShadow: (theme) => ({ md: theme.shadows[20] }),
        }),
        ...(index === 3 && {
          py: { xs: 5, md: 8 },
          boxShadow: (theme) => ({ md: theme.shadows[20] }),
        }),
      }}
    >
      <Image
        alt={icon}
        src={icon}
        sx={{ width: 125, height: 108, mx: 'auto' }}
      />

      <Stack spacing={1} sx={{ my: 5, height: 100 }}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text}
        </Typography>
      </Stack>

      <RouterLink to={path}>
        <Iconify icon={directionStraightRight} />
      </RouterLink>
    </Card>
  );
}
