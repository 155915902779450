import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import type {
  UserClubsApiResponse,
  GroupInfo,
  ClubShortInfo,
} from 'src/types/clubinfo';

import type { RootState } from 'src/store';
import { equal } from 'src/utils/javascript';

/* interface clubListType {
  clubs: ClubShortInfo[]; // returned search result, a list of clubs.
  totalDocCount: number;
} */

interface clubListState {
  clublist: ClubShortInfo[];
  isLoading: boolean;
}

const initialState: clubListState = {
  isLoading: false,
  clublist: [],
};

const slice = createSlice({
  name: 'userclubs',
  initialState,
  reducers: {
    fetchClubs(
      state: clubListState,
      action: PayloadAction<UserClubsApiResponse>
    ) {
      const { clublist } = action.payload;

      state.clublist = clublist;
    },
    fetchGroups(
      state: clubListState,
      action: PayloadAction<{ groups: GroupInfo[]; clubid: string }>
    ) {
      const { groups, clubid } = action.payload;
      state.clublist = state.clublist
        ? state.clublist.map((club) => {
            if (club.clubid === clubid) {
              if (
                club.groups &&
                groups &&
                club.groups.length === groups.length
              ) {
                club.groups.forEach((group, index) => (group = group[index])); // this copy is to make clublist unchanged so avoid triggering useEffect update.
              } else {
                club.groups = groups; // this assginment will trigger clublist change and useEffect update
              }

              return club;
            }
            return club;
          })
        : [];
    },
    addClubs(state: clubListState, action) {
      const { club } = action.payload;
      const array: any = state.clublist;
      array.push(club);
      state.clublist = array;
    },
    editClubs(state: clubListState, action) {
      const { club, index } = action.payload;
      const array: any = state.clublist;
      array[index] = { ...array[index], ...club };
      state.clublist = array;
    },

    addGroupToClub(state: clubListState, action) {
      const { cluburl, group } = action.payload;
      state.clublist = state.clublist.map((club: any) => {
        if (club.cluburl === cluburl) {
          club.groups ? club.groups.push(group) : (club.groups = [group]);
          return club;
        } else {
          return club;
        }
      });
    },
    removeGroupFromClub(
      state: clubListState,
      action: PayloadAction<{ selectedGroups: any; groups: any; clubUrl: any }>
    ) {
      const { selectedGroups, groups, clubUrl } = action.payload;
      state.clublist= state.clublist?.map((club: any) => {
        if (equal(club.cluburl,clubUrl)) {
          club.groups = groups?.filter(
            (data) => !selectedGroups[0]?.name.includes(data?.name)
          );
          return club;
        } else {
          return club;
        }
      });
    },
    setLoadingTrue(state: clubListState) {
      state.isLoading = true;
    },
    setLoadingFalse(state: clubListState) {
      state.isLoading = false;
    },
  },
});

export const reducer = slice.reducer;
export const fetchGroups =
  (clubid: string): AppThunk =>
  async (dispatch) => {
    try {
      const url = '/api/owner/club/group/' + clubid;
      const response = await axios.get(url);
      if (response.status === 200) {
        dispatch(
          slice.actions.fetchGroups({ groups: response.data.groups, clubid })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

export const addGroupToClub =
  (cluburl: string, group: GroupInfo): AppThunk =>
  async (dispatch, getState) => {
    try {
      const url = '/api/owner/club/group';
      console.log('groupp:', group);
      const clubid = getState().userclubs.clublist.find(
        (club: ClubShortInfo) => club.cluburl === cluburl
      ).clubid; // get clubid from store state.userclubs.clublist
      const data = { clubid, groups: [group] };
      const response = await axios.patch(url, data);
      if (response.status === 201) {
        dispatch(slice.actions.addGroupToClub({ cluburl, group }));
      }
    } catch (err) {
      console.log(err);
    }
  };
// update a club
export const editClubList =
  (param: ClubShortInfo, index: string): AppThunk =>
  async (dispatch, getState) => {
    const url = '/api/owner/club/group';
    const clubid = getState().userclubs.clublist[index].clubid; // get clubid from store state.userclubs.clublist
    const data = { clubid, groups: param.groups };
    try {
      const response = await axios.patch(url, data);

      if (response.status === 201) {
        dispatch(slice.actions.editClubs({ club: param, index: index }));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const removeGroupFromClub =
  (selectedGroups: GroupInfo[], groups: GroupInfo[], clubUrl: any): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.removeGroupFromClub({ selectedGroups, groups, clubUrl })
    );
  };

export const addClubList =
  (param: ClubShortInfo): AppThunk =>
  async (dispatch) => {
    const url = '/api/owner/club/';
    const data = { ...param };
    try {
      const response = await axios.post(url, data);
      if (response.status === 201) {
        dispatch(slice.actions.addClubs({ club: param }));
      }
    } catch (err) {}
  };

export const fetchClubs = (): AppThunk => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoadingTrue());

    const url = '/api/user/clublist';

    const response = await axios.get<ClubShortInfo[]>(url);

    dispatch(slice.actions.fetchClubs({ clublist: response.data }));
    dispatch(slice.actions.setLoadingFalse());
    return response.status;
  } catch (err) {
    dispatch(slice.actions.setLoadingFalse());
    console.log(`x ${err} `);
  }
};

export const selectClubs = (state: RootState): ClubShortInfo[] =>
  state.userclubs.clublist;
export const selectClubListLoadingStatus = (state: RootState): boolean =>
  state.userclubs.isLoading;

export default slice;
