import mock from 'src/utils/mock';
import { eventDataType } from 'src/types/scheduletype';

export const pastEventData: eventDataType[] = [
  {
    name: 'Beginner class',
    totalStudent: 12,
    availableSeats: 2,
    date: '8/1-8/31',
    country: 'UNITED STATES',
    state: 'CA',
    city: 'San duego',
    postalCode: '92131',
    startDate: '9/2/2022',
    endDate: '11/27/2022',
    weeklySlot: [
      { day: 'Monday', starttime: '11:10AM', endtime: '12:00AM' },
      { day: 'Tuesday', starttime: '9:00AM', endtime: '10:00AM' },
      { day: 'Wednesday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Thursday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Friday', starttime: '11:10AM', endtime: '12:00AM' },
      { day: 'Saturday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Sunday', starttime: '9:10AM', endtime: '10:00AM' },
    ],
    students: [
      {
        id: '1',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },

      {
        id: '2',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '3',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '4',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '5',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '6',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '7',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '8',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '9',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '10',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
    ],
  },
  {
    name: 'Advanced class',
    totalStudent: 10,
    availableSeats: 3,
    date: '8/2-9/20',
    country: 'UNITED STATES',
    state: 'CA',
    city: 'San duego',
    postalCode: '92131',
    startDate: '9/2/2022',
    endDate: '10/27/2022',
    weeklySlot: [
      { day: 'Monday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Tuesday', starttime: '8:00AM', endtime: '11:00AM' },
      { day: 'Wednesday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Thursday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Friday', starttime: '11:10AM', endtime: '12:00AM' },
      { day: 'Saturday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Sunday', starttime: '9:10AM', endtime: '10:00AM' },
    ],
    students: [
      {
        id: '11',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '12',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '13',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
    ],
  },

  {
    name: 'Tournament',
    totalStudent: 3,
    availableSeats: 3,
    date: '9/21',
    country: 'UNITED STATES',
    state: 'CA',
    city: 'San duego',
    postalCode: '92131',
    startDate: '9/2/2022',
    endDate: '10/27/2022',
    weeklySlot: [
      { day: 'Monday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Tuesday', starttime: '9:00AM', endtime: '10:00AM' },
      { day: 'Wednesday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Thursday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Friday', starttime: '12:10AM', endtime: '12:00AM' },
      { day: 'Saturday', starttime: '9:10AM', endtime: '10:00AM' },
      { day: 'Sunday', starttime: '9:10AM', endtime: '10:00AM' },
    ],
    students: [
      {
        id: '17',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '18',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
      {
        id: '19',
        profileUrl: '/static/images/avatars/avatar_2.png',
        name: 'Cao Yu',
        parentName: 'Jack Yu',
        parentLastName: 'Yu',
        parentFirstName: 'Jack',
        clubname: 'Rising Star',
        clubid: '7654321',
        groupName: 'Level 10',
        email: 'cao.yu@yahoo.com',
        phone: '858-1234567',
      },
    ],
  },
];

mock.onGet('/api/pastSchedule').reply(200, { pastEventData });
